import { max, min } from 'lodash';

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

function roundTwoDecimals(num) {
  return Math.round(num * 100) / 100;
}

function roundFourDecimals(num) {
  return Math.round(num * 10000) / 10000;
}

function calculateChartLimit(values, limit = 'max', thresholdRatio = 0.1) {
  const minValue = Number(min(values));
  const maxValue = Number(max(values));
  const threshold = maxValue !== minValue ? (maxValue - minValue) * thresholdRatio : thresholdRatio;

  if (maxValue === 0 && minValue === 0) {
    return limit === 'max' ? 1 : 0;
  }

  const minValueWithThreshold = minValue !== 0 ? minValue - threshold : 0;

  return limit === 'max' ? maxValue + threshold : minValueWithThreshold;
}

function splitStringOnCapitalLetters(str, joinWith = ' ') {
  const words = str?.split(/(?=[A-Z])/) ?? [];

  return words?.join(joinWith);
}

export { isValidHttpUrl, roundTwoDecimals, roundFourDecimals, calculateChartLimit, splitStringOnCapitalLetters };
