import { slice } from 'lodash';
import { createSelector } from 'reselect';

export const selectSearch = createSelector(
  (state) => state.search,
  (search) => search
);

export const selectIsLoadingByType = createSelector(
  selectSearch,
  (state, type) => type,
  ({ isLoadingNodes, isLoadingAccounts, isLoadingRecommendedValidators }, type) => {
    let isLoading;

    if (type === 'accounts') {
      isLoading = isLoadingNodes;
    } else if (type === 'nodes') {
      isLoading = isLoadingAccounts;
    } else if (type === 'recommended') {
      isLoading = isLoadingRecommendedValidators;
    }

    return isLoading;
  }
);

export const selectIsOpenSearchBar = createSelector(selectSearch, ({ isSearchBarOpen }) => isSearchBarOpen);

export const selectAccountSearchResults = createSelector(selectSearch, ({ accounts }) => accounts);

export const selectNodesSearchResults = createSelector(selectSearch, ({ nodes }) => nodes);

export const selectSearchResults = createSelector(
  selectAccountSearchResults,
  selectNodesSearchResults,
  (state, type) => type,
  (accounts, nodes, type) => (type === 'accounts' ? accounts : nodes)
);

export const selectFound = createSelector(
  selectSearch,
  (state, type) => type,
  (search, type) => (type === 'nodes' ? search.nodeFound : search.accountFound)
);

export const selectRecommendedValidators = createSelector(
  selectSearch,
  (state, count) => count,
  (search, count) => slice(search?.recommendedValidators, 0, count)
);
