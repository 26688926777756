function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/'),
  },
  validators: {
    root: path(ROOTS_DASHBOARD, '/validators'),
  },
  validator: {
    root: path(ROOTS_DASHBOARD, '/validator'),
    view: (id, tabId = 'dashboard') => path(ROOTS_DASHBOARD, `/validator/${id}/tab/${tabId}`),
  },
  delegator: {
    root: path(ROOTS_DASHBOARD, '/delegator'),
    view: (id, tabId = 'dashboard') => path(ROOTS_DASHBOARD, `/delegator/${id}/tab/${tabId}`),
  },
  search: {
    root: path(ROOTS_DASHBOARD, '/search'),
    view: (term) => path(ROOTS_DASHBOARD, `/search/${term}`),
  },
  resources: {
    resources: path(ROOTS_DASHBOARD, '/projects'),
    markets: path(ROOTS_DASHBOARD, '/markets'),
  },
};
