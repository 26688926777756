import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Box } from '@mui/material';

import Image from './Image';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  noLetters: PropTypes.bool,
};

export default function Logo({ disabledLink = false, sx, noLetters = false }) {
  const logoUri = noLetters ? 'nearscope-log-no-letters-40x44-72ppp.png' : 'nearscope-log-with-letters-40x44-72ppp.png';

  const logoNoLetters = (
    <Box sx={{ width: 40, height: 44, ...sx }}>
      <Image sx={{ maxHeight: 44 }} disabledEffect visibleByDefault alt="logo" src={`/logo/${logoUri}`} />
    </Box>
  );

  const logoWithLetters = (
    <Box sx={{ width: 176, height: 44, ...sx }}>
      <Image sx={{ maxHeight: 44 }} disabledEffect visibleByDefault alt="logo" src={`/logo/${logoUri}`} />
    </Box>
  );

  const logo = noLetters ? logoNoLetters : logoWithLetters;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
