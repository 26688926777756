import { createSlice } from '@reduxjs/toolkit';

import { chain, isEmpty, map, mapKeys } from 'lodash';

import axios from '../../utils/axios';

import { dispatch } from '../store';
import { addAlert } from './arch';

import { ALERTS } from '../../constants/near';

const initialState = {
  isLoading: false,
  isLoadingRewards: false,
  isLoadingMetrics: false,
  error: null,
  validator: {},
  rewards: [],
  historical: [],
  delegators: [],
  metrics: {},
  metricsEnabled: false,
};

const slice = createSlice({
  name: 'validator',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    startLoadingRewards(state) {
      state.isLoadingRewards = true;
    },

    startLoadingMetrics(state) {
      state.isLoadingMetrics = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    reset(state) {
      state.isLoading = initialState.isLoading;
      state.isLoadingRewards = initialState.isLoadingRewards;
      state.isLoadingMetrics = initialState.isLoadingMetrics;
      state.error = initialState.error;
      state.validator = initialState.validator;
      state.rewards = initialState.rewards;
      state.historical = initialState.historical;
      state.delegators = initialState.delegators;
      state.metrics = initialState.metrics;
      state.metricsEnabled = initialState.metricsEnabled;
    },

    getValidatorSuccess(state, action) {
      state.isLoading = false;
      state.validator = action.payload;
    },

    getValidatorRewardsSuccess(state, action) {
      state.isLoadingRewards = false;
      state.rewards = action.payload;
    },

    getValidatorHistoricalSuccess(state, action) {
      state.isLoading = false;
      state.historical = action.payload;
    },

    getValidatorDelegatorsSuccess(state, action) {
      state.isLoading = false;
      state.delegators = action.payload;
    },

    getValidatorMetricsSuccess(state, action) {
      state.isLoadingMetrics = false;
      state.metricsEnabled = true;
      state.metrics = action.payload;
    },

    getValidatorMetricsError(state) {
      state.isLoadingMetrics = false;
      state.metricsEnabled = false;
    },
  },
});

export default slice.reducer;

// Thunks
function validatorAdapter(validator) {
  const validatorKeysMap = {
    epoch_id: 'epochId',
    account_id: 'accountId',
    is_validator: 'isValidator',
    ip_address: 'ipAddress',
    sync_status: 'syncStatus',
    last_seen: 'lastSeen',
    last_height: 'lastHeight',
    kickout_reason: 'kickoutReason',
    kickout_value: 'kickoutValue',
    is_slashed: 'isSlashed',
    num_produced_blocks: 'numProducedBlocks',
    num_expected_blocks: 'numExpectedBlocks',
    num_produced_chunks: 'numProducedChunks',
    num_expected_chunks: 'numExpectedChunks',
    country_code: 'countryCode',
    peer_count: 'peerCount',
    stake_next_epoch: 'stakeNextEpoch',
  };

  return mapKeys(validator, (_, key) => validatorKeysMap[key] || key);
}

export function getValidator(accountId) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/db/epoch/nodes/${accountId}`);
      dispatch(slice.actions.getValidatorSuccess(validatorAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

const rewardKeysMap = {
  epoch_id: 'epochId',
  epoch_height: 'epochHeight',
  start_date: 'epochStartDate',
  account_id: 'accountId',
  balance: 'poolBalance',
  commission: 'poolCommission',
  owner_account: 'ownerAccount',
  owner_account_balance: 'ownerAccountBalance',
  owner_account_stake_ratio: 'ownerAccountStakeRatio',
  owner_account_stake_rewards: 'ownerAccountStakeRewards',
  pool_commission_rewards: 'poolCommissionRewards',
  pool_total_distributed_rewards: 'poolTotalDistributedRewards',
  pool_total_rewards: 'poolTotalRewards',
  total_rewards: 'totalRewards',

  is_slashed: 'isSlashed',
  is_validator: 'isValidator',
  kickout_reason: 'kickoutReason',
  kickout_value: 'kickoutValue',
  num_expected_blocks: 'numExpectedBlocks',
  num_expected_chunks: 'numExpectedChunks',
  num_produced_blocks: 'numProducedBlocks',
  num_produced_chunks: 'numProducedChunks',
  peer_count: 'peerCount',
  sync_status: 'syncStatus',
  stake_next_epoch: 'stakeNextEpoch',
};

function rewardsAdapter(rewards) {
  return map(rewards, (reward) => mapKeys(reward, (_, key) => rewardKeysMap[key] || key));
}

export function getValidatorRewards(accountId, epochsCount = 1) {
  return async () => {
    // dispatch(slice.actions.startLoadingRewards());

    try {
      const response = await axios.get(`/network/validators/${accountId}/rewards/${epochsCount}`);
      dispatch(slice.actions.getValidatorRewardsSuccess(rewardsAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function historicalStatusAdapter(rewards) {
  return chain(rewards)
    .map((reward) => mapKeys(reward, (_, key) => rewardKeysMap[key] || key))
    .map((reward) => ({ ...reward, poolCommission: Number(reward.poolCommission) }))
    .value();
}

export function getValidatorHistoricalStatus(accountId, epochsCount = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/validators/${accountId}/status/${epochsCount}`);
      dispatch(slice.actions.getValidatorHistoricalSuccess(historicalStatusAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function delegatorsAdapter(delegators) {
  const delegatorKeysMap = {
    epoch_height: 'epochHeight',
    account_id: 'accountId',
    staked_balance: 'staked',
    unstaked_balance: 'unstaked',
    can_withdraw: 'canWithdraw',
  };

  return map(delegators, (delegator) => mapKeys(delegator, (_, key) => delegatorKeysMap[key] || key));
}

export function getValidatorDelegators(poolId, epochsCount = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/validators/${poolId}/delegators/${epochsCount}`);
      dispatch(slice.actions.getValidatorDelegatorsSuccess(delegatorsAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function validatorMetricsAdapter(metrics) {
  const metricsMap = {
    near_block_height_head: 'nearBlockHeightHead',
    near_block_processed_total: 'nearBlockProcessedTotal',
    near_block_produced_total: 'nearBlockProducedTotal',
    near_boot_time_seconds: 'nearBootTimeSeconds',
    near_chunk_produced_total: 'nearChunkProducedTotal',
    near_chunk_tail_height: 'nearChunkTailGeight',
    near_chunks_processed: 'nearChunksProcessed',
    near_cpu_usage_ratio: 'nearCpuUsageRatio',
    near_http_prometheus_requests_total: 'nearHttpPrometheusRequestsTotal',
    near_http_status_requests_total: 'nearHttpStatusRequestsTotal',
    near_memory_usage_bytes: 'nearMemoryUsageBytes',
    near_node_db_version: 'nearNodeDbVersion',
    near_node_protocol_version: 'nearNodeProtocolVersion',
    near_num_orphans: 'nearNumOrphans',
    near_peer_message_received_total: 'nearPeerMessageReceivedTotal',
    near_peer_reachable: 'nearPeerReachable',
    near_peer_unreliable: 'nearPeerUnreliable',
    near_received_bytes_per_second: 'nearReceivedBytesPerSecond',
    near_routing_table_recalculation_seconds_sum: 'nearRoutingTableRecalculationSecondsSum',
    near_routing_table_recalculation_seconds_count: 'nearRoutingTableRecalculationSecondsCount',
    near_rpc_total_count: 'nearRpcTotalCount',
    'near_rpc_total_count{method="block"}': 'nearRpcTotalCountBlock',
    'near_rpc_total_count{method="chunk"}': 'nearRpcTotalCountChunk',
    'near_rpc_total_count{method="network_info"}': 'nearRpcTotalCountNetworkInfo',
    near_sync_status: 'nearSyncStatus',
    'near_telemetry_result{success="failed"}': 'nearTelemetryResultFailed',
    'near_telemetry_result{success="ok"}': 'nearTelemetryResultOk',
    near_build_info: 'nearBuildInfo',
  };

  return mapKeys(metrics, (_, key) => metricsMap[key] || key);
}

export function getValidatorMetrics(ips, firstRequest = false) {
  return async () => {
    if (firstRequest) {
      dispatch(slice.actions.startLoadingMetrics());
    }

    try {
      const response = await axios.get(`/node/metrics/${ips[0]}`, { timeout: 5000 });

      if (isEmpty(response)) {
        dispatch(slice.actions.getValidatorMetricsError());
      } else {
        dispatch(slice.actions.getValidatorMetricsSuccess(validatorMetricsAdapter(response.data)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getValidatorMetricsError());
      dispatch(addAlert(ALERTS.warningMetricsUnavailable));
    }
  };
}

export function resetError() {
  return async () => {
    dispatch(slice.actions.hasError(false));
  };
}

export function reset() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
