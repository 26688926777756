import numeral from 'numeral';

import { NEAR_SYMBOL } from '../constants/general';

export function fCurrency(number, symbol = '$') {
  const numberFormatted = numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
  return `${symbol} ${numberFormatted}`;
}

export function fCurrencyFourDecimals(number, symbol = '$') {
  const numberFormatted = numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.0000');
  return `${symbol} ${numberFormatted}`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fPercentTwoDecimals(number) {
  return numeral(number / 100).format('0.00%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fNumberTwoDecimals(number) {
  return numeral(number).format('0.00').replace('.00', '');
}

export function fNumberFourDecimals(number) {
  return numeral(number).format('0.0000').replace('.0000', '');
}

export function fShortenNumberInteger(number) {
  return numeral(number).format('0a');
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fShortenNumberThreeDecimals(number) {
  return numeral(number).format('0.000a').replace('.000', '');
}

export function fShortenNumberFourDecimals(number) {
  return numeral(number).format('0.0000a').replace('.0000', '');
}

export function fInteger(number) {
  return numeral(number).format('0');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fNumberTwoDigits(number) {
  return numeral(number).format('00');
}

export function convertYoctoToNear(number) {
  return number > 10 ** 18 ? number / 10 ** 24 : 0;
}

export function convertNearToYocto(number) {
  return number * 10 ** 24;
}

export function fNear(number, shorten = false, withSymbol = true) {
  const formatFunction = shorten ? fShortenNumber : fNumber;
  return `${formatFunction(number)}${withSymbol ? ` ${NEAR_SYMBOL}` : ''}`;
}

export function fYoctoNear(number, shorten = false, withSymbol = true) {
  const formatFunction = shorten ? fShortenNumber : fNumber;
  return `${formatFunction(convertYoctoToNear(number))}${withSymbol ? ` ${NEAR_SYMBOL}` : ''}`;
}

export function fNearWithTwoDecimals(number, withSymbol = true) {
  return `${fNumberTwoDecimals(number)}${withSymbol ? ` ${NEAR_SYMBOL}` : ''}`;
}

export function fNearWithFourDecimals(number, withSymbol = true) {
  return `${fNumberFourDecimals(number)}${withSymbol ? ` ${NEAR_SYMBOL}` : ''}`;
}

export function fYoctoNearWithFourDecimals(number, withSymbol = true) {
  return `${fShortenNumberFourDecimals(convertYoctoToNear(number))}${withSymbol ? ` ${NEAR_SYMBOL}` : ''}`;
}

export function fBytes(number) {
  let units = '';
  let size = Number(number);

  if (number < 1024) {
    units = 'B';
  } else if (number < 1024 * 1024) {
    units = 'KB';
    size = number / 1024;
  } else if (number < 1024 * 1024 * 1024) {
    units = 'MB';
    size = number / (1024 * 1024);
  } else if (number < 1024 * 1024 * 1024 * 1024) {
    units = 'GB';
    size = number / (1024 * 1024 * 1024);
  } else if (number < 1024 * 1024 * 1024 * 1024 * 1024) {
    units = 'TB';
    size = number / (1024 * 1024 * 1024 * 1024);
  }

  return [size, units];
}
