import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import { Buffer } from 'buffer';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import LoadingScreen from '../components/LoadingScreen';

// HACK : needed to avoid near-api-js error (index.js:42 Uncaught (in promise) ReferenceError: Buffer is not defined)
global.Buffer = Buffer;

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/dashboard" replace />, index: true },
        { element: <PageDashboard />, index: true },

        { path: 'validators', element: <PageValidators /> },

        {
          path: 'delegator/*',
          element: <PageDelegator />,
          children: [{ path: ':accountId/tab/:tabId', element: <PageDelegator /> }],
        },

        {
          path: 'validator/*',
          element: <PageValidator />,
          children: [{ path: ':accountId/tab/:tabId', element: <PageValidator /> }],
        },

        { path: 'search', element: <PageSearch /> },
        { path: 'search/:term', element: <PageSearch /> },

        { path: 'projects', element: <PageResources /> },

        { path: 'markets', element: <PageMarkets /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const PageDashboard = Loadable(lazy(() => import('../pages/PageDashboard')));
const PageValidators = Loadable(lazy(() => import('../pages/PageValidators')));
const PageDelegator = Loadable(lazy(() => import('../pages/PageDelegator')));
const PageValidator = Loadable(lazy(() => import('../pages/PageValidator')));
const PageSearch = Loadable(lazy(() => import('../pages/PageSearch')));
const PageResources = Loadable(lazy(() => import('../pages/PageResources')));
const PageMarkets = Loadable(lazy(() => import('../pages/PageMarkets')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
