export const NEAR_SYMBOL = 'Ⓝ';

export const NANOSECONDS_IN_A_SECOND = 10 ** 9;
export const NANOSECONDS_IN_A_MILLISECOND = 10 ** 6;

export const MEGA = 1024;

export const TIMES = {
  tiny: 5000,
  short: 10000,
  medium: 60000,
  mediumLong: 300000,
  long: 600000,
  huge: 1800000,
};

// CODES AND SYMBOLS: https://www.eurochange.co.uk/travel-money/world-currency-abbreviations-symbols-and-codes-travel-money
export const CURRENCIES = {
  usd: {
    label: 'USD ($)',
    value: 'usd',
    symbol: '$',
    icon: '/flags/4x3/us.svg',
  },
  eur: {
    label: 'EUR (€)',
    value: 'eur',
    symbol: '€',
    icon: '/flags/4x3/eu.svg',
  },
  gbp: {
    label: 'GBP (£)',
    value: 'gbp',
    symbol: '£',
    icon: '/flags/4x3/gb.svg',
  },
  chf: {
    label: 'CHF (CHF)',
    value: 'chf',
    symbol: 'CHF',
    icon: '/flags/4x3/ch.svg',
  },
  jpy: {
    label: 'JPY (¥)',
    value: 'jpy',
    symbol: '¥',
    icon: '/flags/4x3/jp.svg',
  },
  cny: {
    label: 'CNY (¥)',
    value: 'cny',
    symbol: '¥',
    icon: '/flags/4x3/cn.svg',
  },
  krw: {
    label: 'KRW (₩)',
    value: 'krw',
    symbol: '₩',
    icon: '/flags/4x3/kr.svg',
  },
  rub: {
    label: 'RUB (₽)',
    value: 'rub',
    symbol: '₽',
    icon: '/flags/4x3/ru.svg',
  },
  uah: {
    label: 'UAH (₴)',
    value: 'uah',
    symbol: '₴',
    icon: '/flags/4x3/ua.svg',
  },
  inr: {
    label: 'INR (₹)',
    value: 'inr',
    symbol: '₹',
    icon: '/flags/4x3/in.svg',
  },
  vnd: {
    label: 'VND (₫)',
    value: 'vnd',
    symbol: '₫',
    icon: '/flags/4x3/vn.svg',
  },
  cad: {
    label: 'CAD ($)',
    value: 'cad',
    symbol: '$',
    icon: '/flags/4x3/ca.svg',
  },
  aud: {
    label: 'AUD ($)',
    value: 'aud',
    symbol: '$',
    icon: '/flags/4x3/au.svg',
  },
  nzd: {
    label: 'NZD ($)',
    value: 'nzd',
    symbol: '$',
    icon: '/flags/4x3/nz.svg',
  },
  hkd: {
    label: 'HKD ($)',
    value: 'hkd',
    symbol: '$',
    icon: '/flags/4x3/hk.svg',
  },
  sgd: {
    label: 'SGD ($)',
    value: 'sgd',
    symbol: '$',
    icon: '/flags/4x3/sg.svg',
  },
  try: {
    label: 'TRY (₺)',
    value: 'try',
    symbol: '₺',
    icon: '/flags/4x3/tr.svg',
  },
  ars: {
    label: 'ARS ($)',
    value: 'ars',
    symbol: '$',
    icon: '/flags/4x3/ar.svg',
  },
  brl: {
    label: 'BRL (R$)',
    value: 'brl',
    symbol: 'R$',
    icon: '/flags/4x3/br.svg',
  },
  clp: {
    label: 'CLP ($)',
    value: 'clp',
    symbol: '$',
    icon: '/flags/4x3/cl.svg',
  },
  mxn: {
    label: 'MXN ($)',
    value: 'mxn',
    symbol: '$',
    icon: '/flags/4x3/mx.svg',
  },
  vef: {
    label: 'VEF (Bs)',
    value: 'vef',
    symbol: 'Bs',
    icon: '/flags/4x3/ve.svg',
  },
};

export const TOKENS = [
  'bitcoin',
  'ethereum',
  'aurora-near',
  'ref-finance',
  'jumbo-exchange',
  'burrow',
  'sweatcoin',
  'meta-pool',
];
