import { PATH_DASHBOARD } from '../../../routes/paths';

import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  validators: getIcon('ic_analytics'),
  validator: getIcon('ic_banking'),
  delegator: getIcon('ic_user'),
  search: getIcon('ic_search_2'),
  resources: getIcon('ic_bookmarks'),
  markets: getIcon('ic_waterfall_chart'),
};

const sidebarConfig = [
  {
    subheader: 'dashboard',
    items: [
      { title: 'Network', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      { title: 'Validators', path: PATH_DASHBOARD.validators.root, icon: ICONS.validators },
      { title: 'Validator', path: PATH_DASHBOARD.validator.root, icon: ICONS.validator },
      { title: 'Delegator', path: PATH_DASHBOARD.delegator.root, icon: ICONS.delegator },
      { title: 'Search', path: PATH_DASHBOARD.search.root, icon: ICONS.search },
    ],
  },

  {
    subheader: 'external',
    items: [
      // { title: 'Markets', path: PATH_DASHBOARD.resources.markets, icon: ICONS.markets },
      { title: 'Projects', path: PATH_DASHBOARD.resources.resources, icon: ICONS.resources },
      // {
      //   title: 'user',
      //   path: '/dashboard/user',
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Four', path: '/dashboard/user/four' },
      //     { title: 'Five', path: '/dashboard/user/five' },
      //     { title: 'Six', path: '/dashboard/user/six' },
      //   ],
      // },
    ],
  },
];

export default sidebarConfig;
