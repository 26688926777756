import { filter, isEmpty, union, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

import { NEAR_NETWORK_MAINNET, NEAR_NETWORK_TESTNET } from '../../constants/near';

import { walletConnect } from '../../configNearWallet';

const initialState = {
  isLoading: false,
  error: null,
  network: NEAR_NETWORK_MAINNET,
  wallet: {
    signedIn: false,
    accountId: '',
  },
  alerts: [],
};

const slice = createSlice({
  name: 'arch',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    changeNetwork(state, action) {
      state.network = action.payload;
    },

    addAlert(state, action) {
      state.alerts = uniqBy(union([action.payload], state.alerts), 'type');
    },

    deleteAlert(state, action) {
      if (isEmpty(state.alerts)) {
        return;
      }

      state.alerts = filter(state.alerts, (alert) => alert.type !== action.payload?.type);
    },

    resetAlerts(state) {
      state.alerts = initialState.alerts;
    },

    signWallet(state, action) {
      state.wallet.signedIn = true;
      state.wallet.accountId = action.payload;
    },

    signOutWallet(state) {
      state.wallet.signedIn = false;
      state.wallet.accountId = '';
    },
  },
});

// Reducer
export default slice.reducer;

export function changeNetwork(network) {
  return async () => {
    if (![NEAR_NETWORK_MAINNET, NEAR_NETWORK_TESTNET].includes(network)) {
      dispatch(slice.actions.hasError('unknown network'));
    }

    dispatch(slice.actions.changeNetwork(network));
  };
}

export function addAlert(alert = {}) {
  return async () => !isEmpty(alert) && dispatch(slice.actions.addAlert(alert));
}

export function deleteAlert(alert = {}) {
  return async () => dispatch(slice.actions.deleteAlert(alert));
}

export function resetAlerts() {
  return async () => dispatch(slice.actions.resetAlerts());
}

export function signWallet() {
  return async (dispatch, getState) => {
    const network = getState().arch?.network;

    if (isEmpty(network)) {
      dispatch(slice.actions.hasError('no network'));
      return;
    }

    try {
      const { walletConnection } = await walletConnect(network);

      if (!walletConnection) {
        dispatch(slice.actions.hasError('no wallet'));
        return;
      }

      if (walletConnection.isSignedIn()) {
        // localStorage.setItem('nearscope_wallet_disconnected', JSON.stringify(false));
        dispatch(slice.actions.signWallet(walletConnection.getAccountId()));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestSignInWallet() {
  return async (dispatch, getState) => {
    const network = getState().arch?.network;

    if (isEmpty(network)) {
      dispatch(slice.actions.hasError('no network'));
      return;
    }

    try {
      const { walletConnection } = await walletConnect(network);

      if (!walletConnection) {
        dispatch(slice.actions.hasError('no wallet'));
        return;
      }

      if (!walletConnection.isSignedIn()) {
        const poolId = getState().validator?.accountId;
        // localStorage.setItem('nearscope_wallet_disconnected', JSON.stringify('connecting'));
        await walletConnection.requestSignIn({ contractId: poolId, methodNames: [] });
      }
      // else {
      //   localStorage.setItem('nearscope_wallet_disconnected', JSON.stringify(false));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function signOutWallet() {
  return async (dispatch) => {
    const { walletConnection } = await walletConnect();

    if (!walletConnection) {
      dispatch(slice.actions.hasError('no wallet'));
      return;
    }

    try {
      if (walletConnection.isSignedIn()) {
        await walletConnection.signOut();
        dispatch(slice.actions.signOutWallet());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
