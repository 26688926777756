import axios from 'axios';
import axiosRetry from 'axios-retry';

import { store } from '../redux/store';

import { HOST_API } from '../config';
import { NEAR_NETWORK_MAINNET } from '../constants/near';

const axiosInstance = axios.create({
  baseURL: HOST_API[NEAR_NETWORK_MAINNET],
});

axiosRetry(axiosInstance, { retries: 5, retryDelay: (retry) => retry * 1000 });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

// NOTE: better to declare it as a function as a closure memoizer, so internal async function is not created every time
const addDynamicBaseUrlInterceptor = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const reduxState = store.getState();
      config.baseURL = HOST_API[reduxState?.arch?.network || config.baseURL];

      return config;
    },
    (error) => Promise.reject(error)
  );
};

addDynamicBaseUrlInterceptor(NEAR_NETWORK_MAINNET);

export default axiosInstance;
