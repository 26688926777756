import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

import { dispatch } from '../store';
import { addAlert } from './arch';

import { ALERTS } from '../../constants/near';

const initialState = {
  isLoadingAccounts: false,
  isLoadingNodes: false,
  isLoadingRecommendedValidators: false,
  isSearchBarOpen: false,
  error: null,
  accountFound: false,
  nodeFound: false,
  accounts: [],
  nodes: [],
  recommendedValidators: [],
};

function getLoadingPropertyName(type) {
  let propertyName;

  if (type === 'accounts') {
    propertyName = 'isLoadingAccounts';
  } else if (type === 'nodes') {
    propertyName = 'isLoadingNodes';
  } else if (type === 'recommended') {
    propertyName = 'isLoadingRecommendedValidators';
  }

  return propertyName;
}

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    startLoading(state, action) {
      const propertyName = getLoadingPropertyName(action.payload);

      state[propertyName] = true;
      state.accountFound = false;
      state.nodeFound = false;
    },

    hasError(state, action) {
      const propertyName = getLoadingPropertyName(action.payload);

      state[propertyName] = false;
      state.error = action.payload;
    },

    getAccountsSearchSuccess(state, action) {
      state.isLoadingAccounts = false;
      state.accountFound = action.payload?.account?.account_id || action.payload?.account?.id;
      state.accounts = action.payload.accounts;
    },

    getNodesSearchSuccess(state, action) {
      state.isLoadingNodes = false;
      state.nodeFound = action.payload?.node?.account_id;
      state.nodes = action.payload.nodes;
    },

    getRecommenedeValidatorsSuccess(state, action) {
      state.isLoadingRecommendedValidators = false;
      state.recommendedValidators = action.payload;
    },

    resetFound(state) {
      state.accountFound = false;
      state.nodeFound = false;
    },

    toggleSearchBar(state, action) {
      state.isSearchBarOpen = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getAccountsSearch(term) {
  return async () => {
    dispatch(slice.actions.startLoading('accounts'));

    try {
      const { data } = await axios.get(`/db/search/accounts/${term}`);
      dispatch(slice.actions.getAccountsSearchSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'accounts' }));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function getNodesSearch(term) {
  return async () => {
    dispatch(slice.actions.startLoading('nodes'));

    try {
      const { data } = await axios.get(`/db/search/nodes/${term}`);
      dispatch(slice.actions.getNodesSearchSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'nodes' }));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function recommendedValidatorsAdapter(validators) {
  return map(validators, (validator) => ({
    poolId: validator.account_id,
    name: validator.name,
    url: validator.url,
    logo: validator.logo,
    version: validator.version,
    status: validator.status,
    stakedBalance: validator.stake_next_epoch,
    commission: Number(validator.commission),
    apy: Number(validator.apy) - (Number(validator.apy) * Number(validator.commission)) / 100,
    epochCount: validator.epoch_count,
    performance: validator.performance * 100,
    infoScore: validator.info_score,
    overallScore: validator.overall_score,
    randomScore: validator.random_score,
    updatedScore: validator.updated_score,
    score: validator.score,
  }));
}

export function getRecommendedValidators(epochsCound = 50) {
  return async () => {
    dispatch(slice.actions.startLoading('recommended'));

    try {
      const { data } = await axios.get(`/db/epoch/nodes/recommended/${epochsCound}`);
      dispatch(slice.actions.getRecommenedeValidatorsSuccess(recommendedValidatorsAdapter(data)));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'recommended' }));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function resetFound() {
  return async () => {
    dispatch(slice.actions.resetFound());
  };
}

export function toggleSearchBar(isOpen) {
  return async () => {
    dispatch(slice.actions.toggleSearchBar(isOpen));
  };
}
