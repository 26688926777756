import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
import Iconify from '../../../components/Iconify';

import { dispatch } from '../../../redux/store';
import { signOutWallet, signWallet, requestSignInWallet } from '../../../redux/slices/arch';
import { selectWalletAccountId } from '../../../redux/selectors/arch';

// import useLocalStorage from '../../../hooks/useLocalStorage';

import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import { getNodeName } from '../../../utils/nearscope/general';

import { PATH_DASHBOARD } from '../../../routes/paths';

const renderSignedInPopover = (open, accountId, handleClose, handleWalletSignOut, handleNavigateToAccount, theme) => (
  <MenuPopover
    open={Boolean(open)}
    anchorEl={open}
    onClose={handleClose}
    sx={{
      p: 0,
      mt: 1.5,
      ml: 0.75,
      '& .MuiMenuItem-root': {
        typography: 'body2',
        borderRadius: 0.75,
      },
    }}
  >
    <Box
      sx={{ my: 1.5, px: 2.5, cursor: 'pointer', '&:hover': { backgroundColor: theme.palette.background.neutral } }}
      onClick={() => handleNavigateToAccount(accountId)}
    >
      <Typography variant="subtitle2" noWrap>
        {getNodeName('', accountId)}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
        {accountId}
      </Typography>
    </Box>

    <Divider sx={{ borderStyle: 'dashed' }} />

    <MenuItem sx={{ m: 1 }} onClick={handleWalletSignOut}>
      Disconnect
    </MenuItem>
  </MenuPopover>
);

const renderSignedOutPopover = (open, accountId, handleClose, handleWalletSignIn) => (
  <MenuPopover
    open={Boolean(open)}
    anchorEl={open}
    onClose={handleClose}
    sx={{
      p: 0,
      mt: 1.5,
      ml: 0.75,
      '& .MuiMenuItem-root': {
        typography: 'body2',
        borderRadius: 0.75,
      },
    }}
  >
    <MenuItem sx={{ m: 1 }} onClick={handleWalletSignIn}>
      Connect Wallet
    </MenuItem>
  </MenuPopover>
);

export default function AccountPopover() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  // const [walletDisconnected, setWalletDisconnected] = useLocalStorage('nearscope_wallet_disconnected', true);

  const accountId = useSelector(selectWalletAccountId);

  useEffect(() => {
    // if (walletDisconnected === 'connecting') {
    //   debugger;
    //   dispatch(requestSignInWallet());
    // } else if ((!walletDisconnected && isEmpty(accountId)) {
    //   debugger;
    //   dispatch(signWallet());
    // }
    // debugger;
    dispatch(signWallet());
  }, [accountId]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleWalletSignIn = () => {
    // setWalletDisconnected(false);
    dispatch(requestSignInWallet());
    handleClose();
  };

  const handleWalletSignOut = () => {
    // setWalletDisconnected(true);
    dispatch(signOutWallet());
    handleClose();
  };

  const handleNavigateToAccount = (id) => {
    navigate(PATH_DASHBOARD.delegator.view(id), { replace: true });
    handleClose();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.4),
            },
          }),
        }}
      >
        <Iconify
          icon={'eva:person-fill'}
          width={24}
          height={24}
          sx={{ color: isEmpty(accountId) ? 'text.secondary' : 'info.main' }}
        />
      </IconButtonAnimate>

      {!isEmpty(accountId)
        ? renderSignedInPopover(open, accountId, handleClose, handleWalletSignOut, handleNavigateToAccount, theme)
        : renderSignedOutPopover(open, accountId, handleClose, handleWalletSignIn)}
    </>
  );
}
