import { map, keys, values } from 'lodash';
import { useState } from 'react';

import { Box, Typography, Stack, MenuItem } from '@mui/material';

import useSettings from '../../../hooks/useSettings';

import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import Iconify from '../../../components/Iconify';

const MENU_OPTIONS = {
  80: {
    color: 'info.light',
    zoom: 80,
  },
  90: {
    color: 'info.light',
    zoom: 90,
  },
  100: {
    color: 'text.secondary',
    zoom: 100,
  },
  110: {
    color: 'info.main',
    zoom: 110,
  },
  120: {
    color: 'info.main',
    zoom: 120,
  },
};

export default function ThemeZoomPopover() {
  const { themeZoom, onChangeZoom } = useSettings();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (newZoom = null) => {
    if (newZoom && keys(MENU_OPTIONS).includes(newZoom.toString()) && newZoom !== themeZoom) {
      onChangeZoom(newZoom);
    }

    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'action.selected',
            },
          }),
        }}
      >
        <Iconify
          icon={'eva:maximize-outline'}
          width={24}
          height={24}
          sx={{ color: MENU_OPTIONS[themeZoom || 100].color }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          <Typography variant="caption" align="center" sx={{ py: 1, flexShrink: 0, color: 'text.secondary' }}>
            Select zoom level...
          </Typography>

          {map(values(MENU_OPTIONS), ({ zoom: optionZoom, color }) => (
            <MenuItem
              key={optionZoom}
              selected={themeZoom === optionZoom}
              onClick={() => handleClose(optionZoom)}
              sx={{ color }}
            >
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    mr: 1,
                    borderRadius: 1,
                    bgcolor: color,
                  }}
                />
                {`${optionZoom}%`}
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
