import { capitalize, first, last } from 'lodash';

import { isValidHttpUrl } from '../general';

import { fShortenNumber } from '../formatNumber';

import {
  CENTRALIZATION_THRESHOLD_HIGH,
  CENTRALIZATION_THRESHOLD_MID,
  CENTRALIZATION_SCORE_HIGH,
  CENTRALIZATION_SCORE_MID,
  CENTRALIZATION_SCORE_LOW,
  PERFORMANCE_THRESHOLD_WARNING,
  PERFORMANCE_THRESHOLD_KICKOUT,
  COMMISSION_ZERO,
  COMMISSION_THRESHOLD_WARNING,
  COMMISSION_THRESHOLD_WATCH,
  NODE_SYNC_STATUS,
  NEAR_NETWORK_MAINNET,
  NEAR_NETWORK_TESTNET,
  PERFORMANCE_THRESHOLD_NOTICE,
  PERFORMANCE_SCORE_HIGH,
  PERFORMANCE_SCORE_LOW,
  PERFORMANCE_SCORE_MID,
  PERFORMANCE_SCORE_VERY_LOW,
  COMMISSION_SCORE_LOW,
  COMMISSION_SCORE_MID,
  COMMISSION_SCORE_HIGH,
  COMMISSION_ZERO_STAKE_MID_THRESHOLD,
  COMMISSION_ZERO_STAKE_MAX_THRESHOLD,
} from '../../constants/near';

function getNodeName(name = '', accountId = '') {
  return name || capitalize(accountId?.split('.')[0]) || '-';
}

function isValidPoolId(poolId, network = NEAR_NETWORK_MAINNET) {
  if (network === NEAR_NETWORK_TESTNET) {
    return !!poolId.match(/^.+\.pool\.f[0-9]{4,8}\.m0$/i) || !!poolId.match(/^.+\.testnet$/i);
  }

  if (network === NEAR_NETWORK_MAINNET) {
    return !!poolId.match(/^.+\.near$/i);
  }

  return false;
}

function isValidAccountId(accountId, network = NEAR_NETWORK_MAINNET) {
  if (isValidPoolId(accountId)) {
    return false;
  }

  if (network === NEAR_NETWORK_TESTNET) {
    return !!accountId.match(/^.+\.testnet$/i);
  }

  if (network === NEAR_NETWORK_MAINNET) {
    return !!accountId.match(/^.+\.near$/i);
  }

  return false;
}

function getNodeStatus(poolId, proposalNodes, joiningNodes, kickoutNodes, activeNodes) {
  if (proposalNodes.includes(poolId)) return 'proposal';
  if (joiningNodes.includes(poolId)) return 'proposal';
  if (kickoutNodes.includes(poolId)) return 'kickout';
  if (activeNodes.includes(poolId)) return 'active';

  return 'idle';
}

function getOverallColor(blockPerformance, chunkPerformance, status, commission, stakeRatio, stake, theme) {
  let score = 0;
  const centralizationScore = getCentralizationScore(stakeRatio);
  const commissionScore = getCommissionScore(commission, stake);

  const blockPerformanceNumber =
    typeof blockPerformance !== 'number' ? Number(blockPerformance.replace('%', '')) : blockPerformance;
  const chunkPerformanceNumber =
    typeof chunkPerformance !== 'number' ? Number(chunkPerformance.replace('%', '')) : chunkPerformance;
  const averagaPerformance = (blockPerformanceNumber + chunkPerformanceNumber) / 2;

  if (averagaPerformance < PERFORMANCE_THRESHOLD_NOTICE) score += 1;
  if (averagaPerformance < PERFORMANCE_THRESHOLD_WARNING) score += 1;
  if (averagaPerformance < PERFORMANCE_THRESHOLD_KICKOUT) score += 1;

  if (commissionScore > COMMISSION_SCORE_MID) score += 3;
  if (commissionScore === COMMISSION_SCORE_MID) score += 2;

  if (centralizationScore === CENTRALIZATION_SCORE_HIGH) score += 3;
  if (centralizationScore === CENTRALIZATION_SCORE_MID) score += 1;

  if (score === 2) return theme.palette.warning.main;
  if (score > 2) return theme.palette.error.main;

  return theme.palette.primary.main;
}

function getPerformance(produced = 0, expected = 0, status = 'idle') {
  // NOTE: disabling this bc we're getting from the rpc produced and expected data for inactive nodes too
  // if (status !== 'active') {
  //   return 0;
  // }

  return expected > 0 ? fShortenNumber((produced / expected) * 100) : 100;
}

function getPerformanceScore(performance) {
  if (performance > PERFORMANCE_THRESHOLD_NOTICE) return PERFORMANCE_SCORE_HIGH;
  if (performance > PERFORMANCE_THRESHOLD_WARNING) return PERFORMANCE_SCORE_MID;
  if (performance > PERFORMANCE_THRESHOLD_KICKOUT) return PERFORMANCE_SCORE_LOW;

  return PERFORMANCE_SCORE_VERY_LOW;
}

function getPerformanceColor(performance, theme) {
  const isDarkTheme = theme.palette.mode === 'dark';
  const performanceNumber = typeof performance !== 'number' ? Number(performance.replace('%', '')) : performance;

  if (performanceNumber < PERFORMANCE_THRESHOLD_KICKOUT) return theme.palette.error.main;
  if (performanceNumber < PERFORMANCE_THRESHOLD_WARNING)
    return isDarkTheme ? theme.palette.warning.main : theme.palette.warning.darker;
  if (performanceNumber < PERFORMANCE_THRESHOLD_NOTICE)
    return isDarkTheme ? theme.palette.warning.light : theme.palette.warning.main;

  return theme.palette.text.primary;
}

function getCommissionScore(commission, stake) {
  const commissionNumber = typeof commission !== 'number' ? Number(commission.replace('%', '')) : commission;

  if (commissionNumber > COMMISSION_THRESHOLD_WATCH) return COMMISSION_SCORE_HIGH;
  if (commissionNumber === COMMISSION_ZERO && stake >= COMMISSION_ZERO_STAKE_MAX_THRESHOLD)
    return COMMISSION_SCORE_HIGH;
  if (commissionNumber > COMMISSION_THRESHOLD_WARNING) return COMMISSION_SCORE_MID;
  if (commissionNumber === COMMISSION_ZERO && stake >= COMMISSION_ZERO_STAKE_MID_THRESHOLD) return COMMISSION_SCORE_MID;

  return COMMISSION_SCORE_LOW;
}

function getCommissionColor(commission, stake, theme) {
  const commissionScore = getCommissionScore(commission, stake);

  if (commissionScore > COMMISSION_SCORE_MID) return theme.palette.error.main;
  if (commissionScore === COMMISSION_SCORE_MID) return theme.palette.warning.main;

  return theme.palette.text.primary;
}

function getCentralizationScore(stakeRatio) {
  const stakeRatioNumber = typeof stakeRatio !== 'number' ? Number(stakeRatio.replace('%', '')) : stakeRatio;

  if (stakeRatioNumber >= CENTRALIZATION_THRESHOLD_HIGH) return CENTRALIZATION_SCORE_HIGH;
  if (stakeRatioNumber >= CENTRALIZATION_THRESHOLD_MID) return CENTRALIZATION_SCORE_MID;

  return CENTRALIZATION_SCORE_LOW;
}

function getStakeRatioColor(stakeRatio, theme) {
  const isDarkTheme = theme.palette.mode === 'dark';
  const centralizationScore = getCentralizationScore(stakeRatio);

  if (centralizationScore === CENTRALIZATION_SCORE_HIGH)
    return isDarkTheme ? theme.palette.error.main : theme.palette.error.main;
  if (centralizationScore === CENTRALIZATION_SCORE_MID)
    return isDarkTheme ? theme.palette.warning.main : theme.palette.warning.main;

  return theme.palette.primary;
}

function getStakeDiffColor(stakeDiff, theme) {
  const isDarkTheme = theme.palette.mode === 'dark';

  if (stakeDiff > 0) return isDarkTheme ? theme.palette.success.main : theme.palette.success.main;
  if (stakeDiff < 0) return isDarkTheme ? theme.palette.error.main : theme.palette.error.main;

  return theme.palette.secondary;
}

function getStakeDiffIco(stake, stakeDiff) {
  const direction = Number(stakeDiff) > 0 ? 'up' : 'down';
  const bigChange = Number(stakeDiff) / Number(stake) > 0.005;

  if (bigChange) return `eva:arrowhead-${direction}-fill`;

  return `eva:arrow-ios-${direction}ward-fill`;
}

function getActiveNodeLabelColor(blockPerformance, chunkPerformance) {
  const blockPerformanceNumber =
    typeof blockPerformance !== 'number' ? Number(blockPerformance.replace('%', '')) : blockPerformance;
  const chunkPerformanceNumber =
    typeof chunkPerformance !== 'number' ? Number(chunkPerformance.replace('%', '')) : chunkPerformance;
  const averagaPerformance = (blockPerformanceNumber + chunkPerformanceNumber) / 2;

  if (averagaPerformance < PERFORMANCE_THRESHOLD_KICKOUT) return 'error';
  if (averagaPerformance < PERFORMANCE_THRESHOLD_WARNING) return 'warning';

  return 'success';
}

function getNodeSyncStatusColor(nodeSync, isText = true) {
  if (nodeSync === NODE_SYNC_STATUS.synced) return isText ? 'text.primary' : 'success.main';
  if (nodeSync === NODE_SYNC_STATUS.noPeers) return 'error.main';

  return 'warning.main';
}

function getSocialNetworkUri(id, type = 'discord') {
  if (!id || isValidHttpUrl(id)) {
    return id;
  }

  const idWithoutAt = id[0] === '@' ? id.slice(1) : id;

  switch (type) {
    case 'discord':
      return idWithoutAt;

    case 'telegram':
      return `https://t.me/${idWithoutAt}`;

    case 'twitter':
      return `https://twitter.com/${idWithoutAt}`;

    default:
      return id;
  }
}

function getNearWalletStakeWithPoolUri(poolId = '', network = NEAR_NETWORK_MAINNET) {
  if (!isValidPoolId(poolId, network)) {
    return '';
  }

  const uriPrefix = network === NEAR_NETWORK_MAINNET ? 'https://wallet.near.org' : 'https://wallet.testnet.near.org';

  return `${uriPrefix}/staking/${poolId}/stake`;
}

function getNearExplorerAccountUri(accountId = '', network = NEAR_NETWORK_MAINNET) {
  if (!isValidPoolId(accountId, network) && !isValidAccountId(accountId, network)) {
    return '';
  }

  const uriPrefix =
    network === NEAR_NETWORK_MAINNET ? 'https://explorer.near.org' : 'https://explorer.testnet.near.org';

  return `${uriPrefix}/accounts/${accountId}`;
}

function calculateOverallChange(val) {
  const firstItem = Number(first(val));
  const lastItem = Number(last(val));

  return firstItem !== 0 ? ((lastItem - firstItem) / firstItem) * 100 : lastItem;
}

function calculatePoolApy(networkApy, poolCommission) {
  return networkApy - networkApy * (poolCommission / 100);
}

export {
  getNodeName,
  isValidPoolId,
  isValidAccountId,
  getNodeStatus,
  getOverallColor,
  getPerformance,
  getPerformanceScore,
  getPerformanceColor,
  getCommissionColor,
  getCentralizationScore,
  getStakeRatioColor,
  getStakeDiffColor,
  getStakeDiffIco,
  getActiveNodeLabelColor,
  getNodeSyncStatusColor,
  getSocialNetworkUri,
  getNearWalletStakeWithPoolUri,
  getNearExplorerAccountUri,
  calculateOverallChange,
  calculatePoolApy,
};
