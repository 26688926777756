// routes
// import { PATH_DASHBOARD } from './routes/paths';

import { NEAR_NETWORK_MAINNET, NEAR_NETWORK_TESTNET } from './constants/near';

// API
export const HOST_API = {
  [NEAR_NETWORK_MAINNET]: process.env.REACT_APP_HOST_API_KEY_MAINNET,
  [NEAR_NETWORK_TESTNET]: process.env.REACT_APP_HOST_API_KEY_TESTNET,
};

// LAYOUT
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
export const defaultSettings = {
  themeMode: 'light',
  themeZoom: 100,
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
  tableValidatorsStretch: false,
  tableDelegatorsStretch: true,
  tableRewardsStretch: false,
};
