import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Container, Alert } from '@mui/material';

import { useSelector, useDispatch } from '../../redux/store';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

import { deleteAlert } from '../../redux/slices/arch';

import { selectAlerts } from '../../redux/selectors/arch';

import { HEADER, NAVBAR } from '../../config';

import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

function renderAlerts(alerts, themeStretch, dispatch, deleteAlert) {
  if (alerts.length === 0) {
    return null;
  }

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      {alerts.map((alert, i) => (
        <Alert
          key={alert.toString() + i}
          severity={alert.severity}
          onClose={() => {
            dispatch(deleteAlert(alert));
          }}
          sx={{ px: 2, mb: 2 }}
        >
          {alert.message}
        </Alert>
      ))}
    </Container>
  );
}

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout, themeStretch } = useSettings();

  const dispatch = useDispatch();
  const alerts = useSelector(selectAlerts);

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {renderAlerts(alerts, themeStretch, dispatch, deleteAlert)}
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {renderAlerts(alerts, themeStretch, dispatch, deleteAlert)}
        <Outlet />
      </MainStyle>
    </Box>
  );
}
