import { createSlice } from '@reduxjs/toolkit';

import { mapKeys, map, omit } from 'lodash';

import { convertYoctoToNear } from '../../utils/formatNumber';

import axios from '../../utils/axios';

import { dispatch } from '../store';

import { addAlert } from './arch';

import { ACCOUNT_RESERVED_YOCTOS_FOR_TX, ALERTS } from '../../constants/near';

const initialState = {
  isLoading: false,
  isLoadingRewards: false,
  error: null,
  account: {},
  delegations: [],
  delegationsSelection: [],
  epochs: [],
};

const slice = createSlice({
  name: 'validator',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    startLoadingRewards(state) {
      state.isLoadingRewards = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    reset(state) {
      state.isLoading = initialState.isLoading;
      state.isLoadingRewards = initialState.isLoadingRewards;
      state.error = initialState.error;
      state.account = initialState.account;
      state.delegations = initialState.delegations;
      state.delegationsSelection = initialState.delegationsSelection;
      state.epochs = initialState.epochs;
    },

    getAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },

    getAccountDelegationsSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingRewards = false;
      state.delegations = action.payload;
    },

    setAccountDelegationsSelectionSuccess(state, action) {
      state.isLoadingRewards = false;
      state.delegationsSelection = action.payload;
    },

    getDelegatorHistoricalSuccess(state, action) {
      state.isLoading = false;

      if (action.payload[0]?.epochHeight !== state.epochs[0]?.epochHeight) {
        state.epochs = action.payload;
      }
    },
  },
});

export default slice.reducer;

// Thunks
function accountAdapter(account) {
  const accountKeysMap = {
    id: 'accountId',
    block_height: 'blockHeight',
    block_hash: 'blockHash',
    code_hash: 'codeHash',
    storage_paid_at: 'storagePaidAt',
    storage_usage: 'storageUsage',
    locked: 'balanceLocked',
  };

  const { balance } = account;

  return {
    ...mapKeys(omit(account, ['balance']), (_, key) => accountKeysMap[key] || key),
    balanceTotal: balance.total,
    balanceAvailable: Number(balance.total) - (Number(balance.stateStaked) + ACCOUNT_RESERVED_YOCTOS_FOR_TX),
    balanceStaked: balance.staked,
    balanceReservedForStorage: balance.stateStaked,
    balanceReservedForTransactions: convertYoctoToNear(ACCOUNT_RESERVED_YOCTOS_FOR_TX),
  };
}

export function getAccount(accountId) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/accounts/${accountId}`);
      dispatch(slice.actions.getAccountSuccess(accountAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function getAccountOnChain(accountId) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/accounts/${accountId}`);
      dispatch(slice.actions.getAccountSuccess(accountAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function delegationsAdapter(delegations) {
  const delegationKeysMap = {
    epoch_id: 'epochId',
    epoch_height: 'epochHeight',
    start_date: 'epochStartDate',
    account_id: 'accountId',
    pool_id: 'poolId',
    pool_name: 'poolName',
    pool_logo: 'poolLogo',
    pool_commission: 'poolCommission',
    pool_is_validator: 'poolIsValidator',
    pool_status: 'poolStatus',
    pool_num_expected_blocks: 'poolNumExpectedBlocks',
    pool_num_produced_blocks: 'poolNumProducedBlocks',
    staked_balance: 'stakedBalance',
    unstaked_balance: 'unstakedBalance',
    can_withdraw: 'canWithdraw',
  };

  return map(delegations, (delegation) => mapKeys(delegation, (_, key) => delegationKeysMap[key] || key));
}

export function getAccountDelegations(accountId, epochsCount = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingRewards());

    try {
      const response = await axios.get(`/network/accounts/${accountId}/delegations/${epochsCount}`);
      dispatch(slice.actions.getAccountDelegationsSuccess(delegationsAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function accountHistoricalAdapter(statusHistorical) {
  return map(statusHistorical, (status) => ({
    epochId: status.epoch_id,
    epochHeight: status.epoch_height,
    startDate: status.start_date,
    startHeight: status.start_height,
    apy: status.apy,
  }));
}

export function getAccountHistoricalStatus(epochsCount = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/db/epoch/network/${epochsCount}`);
      dispatch(slice.actions.getDelegatorHistoricalSuccess(accountHistoricalAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function setAccountDelegationsSelection(selection) {
  return async () => {
    dispatch(slice.actions.startLoadingRewards());
    dispatch(slice.actions.setAccountDelegationsSelectionSuccess(selection));
  };
}

export function resetError() {
  return async () => {
    dispatch(slice.actions.hasError(false));
  };
}

export function reset() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
