import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { addAlert } from './arch';

import { ALERTS } from '../../constants/near';
import { CURRENCIES } from '../../constants/general';

const initialState = {
  isLoading: false,
  error: null,
  selectedCurrency: CURRENCIES.usd,
  near: {
    price: 0,
    priceChange24Hours: 0,
    priceChangePercentage24Hours: 0,
    allTimeHigh: 0,
    allTimeLow: 0,
    marketCapRank: 0,
    totalSupply: 0,
    circulatingSupply: 0,
    fullyDilutedMarketValuation: 0,
  },
  market: {
    capitalizations: [],
    prices: [],
    totalVolumes: [],
  },
  tokens: {},
  markets: {},
  epochsTokenPrice: [],
};

const slice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedCurrencySuccess(state, action) {
      state.selectedCurrency = action.payload;
    },

    getNearTokenSuccess(state, action) {
      state.isLoading = false;
      state.near = action.payload;
    },

    getTokenSuccess(state, action) {
      state.isLoading = false;
      state.tokens[action.payload.id] = action.payload;
    },

    getNearMarketSuccess(state, action) {
      state.isLoading = false;
      state.market = action.payload;
    },

    getMarketSuccess(state, action) {
      state.isLoading = false;
      state.markets[action.payload.id] = action.payload;
    },

    getEpochsTokenPriceSuccess(state, action) {
      state.isLoading = false;
      state.epochsTokenPrice = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

function tokenAdapter({ data }) {
  return {
    id: data.symbol,
    price: data.current_price,
    priceChange24Hours: data.price_change_24h,
    priceChangePercentage24Hours: data.price_change_percentage_24h,
    allTimeHigh: data.ath,
    allTimeLow: data.atl,
    marketCapRank: data.market_cap_rank,
    totalSupply: data.total_supply,
    circulatingSupply: data.circulating_supply,
    fullyDilutedMarketValuation: data.fully_diluted_valuation,
  };
}

export function getNearToken() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { token } = getState();

    try {
      const response = await axios.get(`/tokens/near/${token.selectedCurrency.value}`);
      dispatch(slice.actions.getNearTokenSuccess(tokenAdapter(response)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function getToken(tokenDenom) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { token } = getState();

    try {
      const response = await axios.get(`/tokens/${tokenDenom}/${token.selectedCurrency.value}`);
      dispatch(slice.actions.getTokenSuccess(tokenAdapter(response)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function marketAdapter({ data }, tokenDenom = 'near') {
  return {
    id: tokenDenom,
    capitalizations: data.market_caps,
    prices: data.prices,
    totalVolumes: data.total_volumes,
  };
}

export function getNearMarket() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { token } = getState();

    try {
      const response = await axios.get(`/market/near/${token.selectedCurrency.value}`);
      dispatch(slice.actions.getNearMarketSuccess(marketAdapter(response)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function getMarket(tokenDenom) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // const { token } = getState();
    // const currency = token.selectedCurrency.value
    const currency = 'usd';

    try {
      const response = await axios.get(`/market/${tokenDenom}/${currency}`);
      dispatch(slice.actions.getMarketSuccess(marketAdapter(response, tokenDenom)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function epochTokenPricesAdapter({ data }) {
  return _.map(data, (epochTokenPrice) => ({
    epochId: epochTokenPrice.epoch_id,
    epochHeight: epochTokenPrice.epoch_height,
    tokenTo: epochTokenPrice.token_to,
    // price: Number(epochTokenPrice.price),
    price: Number(epochTokenPrice.current_price),
  }));
}

export function getEpochsTokenPrice(currency, epochsCount = 1) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      // const response = await axios.get(`/db/epoch/token-prices/all/epochs/${epochsCount}`);
      const response = await axios.get(`/db/epoch/token-prices/near/currency/${currency}/epochs/${epochsCount}`);
      dispatch(slice.actions.getEpochsTokenPriceSuccess(epochTokenPricesAdapter(response)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

export function setSelectedCurrency(currency = 'usd') {
  return async (dispatch, getState) => {
    const { token } = getState();

    if (!CURRENCIES[currency] || token?.selectedCurrency?.value === currency) {
      return;
    }

    dispatch(slice.actions.setSelectedCurrencySuccess(CURRENCIES[currency]));
    dispatch(getNearToken(currency));
    dispatch(getNearMarket(currency));
  };
}
