import * as nearAPI from 'near-api-js';

const { connect, keyStores, WalletConnection } = nearAPI;

const connectionConfig = {
  testnet: {
    networkId: 'testnet',
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: 'https://rpc.testnet.near.org',
    walletUrl: 'https://wallet.testnet.near.org',
    helperUrl: 'https://helper.testnet.near.org',
    explorerUrl: 'https://explorer.testnet.near.org',
  },

  mainnet: {
    networkId: 'mainnet',
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: 'https://rpc.mainnet.near.org',
    walletUrl: 'https://wallet.mainnet.near.org',
    helperUrl: 'https://helper.mainnet.near.org',
    explorerUrl: 'https://explorer.mainnet.near.org',
  },
};

const walletConnect = async (network = 'mainnet') => {
  if (!connectionConfig[network]) {
    return { nearConnection: null, walletConnection: null };
  }

  const nearConnection = await connect(connectionConfig[network]);
  const walletConnection = new WalletConnection(nearConnection, 'nearscope');

  return { nearConnection, walletConnection };
};

export { walletConnect };
