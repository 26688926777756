import { Link as RouterLink } from 'react-router-dom';

import { Stack, Button, Typography, Link } from '@mui/material';

import { PATH_DASHBOARD } from '../../../routes/paths';

import Iconify from '../../../components/Iconify';

import { MaintenanceIllustration } from '../../../assets';

import { POOL_ACCOUNT_ID_DEXAGON } from '../../../constants/near';

const buttonColor = Math.floor(Math.random() * 2) === 1 ? 'info' : 'success';

export default function NavbarBlockscopeAd() {
  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, mb: 2, width: 1, textAlign: 'center', display: 'block' }}>
      <MaintenanceIllustration sx={{ width: 1, mb: 3 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Help us build the Near ecosystem
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
          Help us improve Nearscope and create more awesome Near tools by delegating to{' '}
          <Link component={RouterLink} to={PATH_DASHBOARD.validator.view(POOL_ACCOUNT_ID_DEXAGON)} variant="body2">
            Blockscope.net pool
          </Link>{' '}
        </Typography>
      </div>

      <Button
        to={PATH_DASHBOARD.validator.view(POOL_ACCOUNT_ID_DEXAGON)}
        size="large"
        variant="outlined"
        startIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
        component={RouterLink}
        color={buttonColor}
      >
        Stake with Us
      </Button>
    </Stack>
  );
}
