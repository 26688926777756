import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { createContext } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';

import { defaultSettings } from '../config';

const initialState = {
  ...defaultSettings,
  onChangeMode: () => {},
  onChangeZoom: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onToggleTableStretch: () => {},
  onResetSetting: () => {},
  setColor: defaultPreset,
  colorOption: [],
};

const SettingsContext = createContext(initialState);

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeZoom: initialState.themeZoom,
    themeDirection: initialState.themeDirection,
    themeColorPresets: initialState.themeColorPresets,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout,
    tableValidatorsStretch: initialState.tableValidatorsStretch,
    tableDelegatorsStretch: initialState.tableDelegatorsStretch,
    tableRewardsStretch: initialState.tableRewardsStretch,
  });

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onChangeZoom = (zoom) => {
    document.body.style.zoom = `${zoom}%`;

    setSettings({
      ...settings,
      themeZoom: zoom,
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value,
    });
  };

  const onChangeLayout = (event) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  const onToggleTableStretch = (type = 'validator') => {
    const types = ['validator', 'delegator', 'reward'];

    if (!types.includes(type)) {
      return;
    }

    const property = `table${capitalize(type)}sStretch`;

    setSettings({ ...settings, [property]: !settings[property] });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeZoom: initialState.themeZoom,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
      tableValidatorsStretch: initialState.tableValidatorsStretch,
      tableDelegatorsStretch: initialState.tableDelegatorsStretch,
      tableRewardsStretch: initialState.tableRewardsStretch,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onChangeZoom,
        onToggleMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main,
        })),
        // Stretch
        onToggleStretch,
        // Navbar Horizontal
        onChangeLayout,
        // Tables stretch
        onToggleTableStretch,
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
