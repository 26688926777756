import { map, keys, values, startCase } from 'lodash';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography, Stack, MenuItem } from '@mui/material';

import { useDispatch, useSelector } from '../../../redux/store';
import { changeNetwork } from '../../../redux/slices/arch';

import { selectNetwork } from '../../../redux/selectors/arch';

import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import Iconify from '../../../components/Iconify';

import { NEAR_NETWORK_MAINNET, NEAR_NETWORK_TESTNET } from '../../../constants/near';
import { PATH_DASHBOARD } from '../../../routes/paths';

const MENU_OPTIONS = {
  [NEAR_NETWORK_MAINNET]: {
    color: 'primary.main',
    label: NEAR_NETWORK_MAINNET,
  },
  [NEAR_NETWORK_TESTNET]: {
    color: 'warning.main',
    label: NEAR_NETWORK_TESTNET,
  },
};

export default function NetworkPopover() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedNetwork = useSelector(selectNetwork);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (network = null) => {
    if (network && keys(MENU_OPTIONS).includes(network) && network !== selectedNetwork) {
      if (pathname?.startsWith('/validator') || pathname?.startsWith('/delegator')) {
        navigate(PATH_DASHBOARD.general.dashboard);
      }

      dispatch(changeNetwork(network));
    }

    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'action.selected',
            },
          }),
        }}
      >
        <Iconify
          icon={'clarity:network-settings-solid'}
          width={24}
          height={24}
          sx={{ color: MENU_OPTIONS[selectedNetwork].color }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          <Typography variant="caption" align="center" sx={{ py: 1, flexShrink: 0, color: 'text.secondary' }}>
            Select network...
          </Typography>

          {map(values(MENU_OPTIONS), ({ label: network, color }) => (
            <MenuItem key={network} selected={network === selectedNetwork} onClick={() => handleClose(network)}>
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    mr: 1,
                    borderRadius: 1,
                    bgcolor: color,
                  }}
                />
                {startCase(network)}
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
