import { map, mapKeys } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

import { dispatch } from '../store';
import { addAlert, deleteAlert } from './arch';

import { ALERTS } from '../../constants/near';

const initialState = {
  isLoading: false,
  error: null,
  pools: [],
};

const slice = createSlice({
  name: 'stakingPools',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getStakingPoolsSuccess(state, action) {
      state.pools = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

function stakingPoolsAdapter(pools) {
  const poolKeysMap = {
    pool_id: 'poolId',
  };

  return map(pools, (pool) => mapKeys(pool, (_, key) => poolKeysMap[key] || key));
}

export function getStakingPools() {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/staking-pools`);
      dispatch(slice.actions.getStakingPoolsSuccess(stakingPoolsAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}
