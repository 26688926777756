import { createSelector } from 'reselect';

import { chain } from 'lodash';

export const selectToken = createSelector(
  (state) => state.token,
  (token) => token
);

export const selectNearToken = createSelector(selectToken, ({ near }) => near);
export const selectSelectedCurrency = createSelector(selectToken, ({ selectedCurrency }) => selectedCurrency);
export const selectTokenMarket = createSelector(selectToken, ({ market }) => market);

export const selectTokens = createSelector(selectToken, ({ tokens }) => tokens);
export const selectMarkets = createSelector(selectToken, ({ markets }) => markets);

export const selectTokenByDenom = createSelector(
  selectTokens,
  (state, denom) => denom,
  (tokens, denom) => tokens[denom]
);

export const selectEpochsTokenPrice = createSelector(selectToken, ({ epochsTokenPrice }) => epochsTokenPrice);

export const selectEpochsTokenPriceByCurrency = createSelector(
  selectEpochsTokenPrice,
  selectSelectedCurrency,
  (epochsTokenPrice, selectedCurrency) =>
    chain(epochsTokenPrice)
      .filter((epochTokenPrice) => epochTokenPrice.tokenTo === selectedCurrency.value)
      .keyBy('epochHeight')
      .value()
);

export const selectPriceChart4HourGranularity = createSelector(selectTokenMarket, ({ prices }) =>
  chain(prices)
    .filter((_, index) => index % 6 === 1)
    .map((timePricePair) => Number(timePricePair[1]))
    .value()
);

export const selectPriceChart4HourGranularityByDenom = createSelector(
  selectMarkets,
  (state, denom) => denom,
  (markets, denom) =>
    chain(markets[denom]?.prices)
      .filter((_, index) => index % 6 === 1)
      .map((timePricePair) => Number(timePricePair[1]))
      .value()
);
