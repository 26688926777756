import { createSelector } from 'reselect';

import { NEAR_NETWORK_TESTNET } from '../../constants/near';

export const selectArch = createSelector(
  (state) => state?.arch,
  (arch) => arch
);

export const selectAlerts = createSelector(selectArch, ({ alerts }) => alerts);

export const selectNetwork = createSelector(selectArch, ({ network }) => network);

export const selectIsConnectedToTestnet = createSelector(selectNetwork, (network) => network === NEAR_NETWORK_TESTNET);

export const selectWallet = createSelector(selectArch, ({ wallet }) => wallet);
export const selectWalletAccountId = createSelector(selectWallet, (wallet) => wallet?.accountId);
