import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import archReducer from './slices/arch';

import clusterReducer from './slices/cluster';
import tokenReducer from './slices/token';
import validatorReducer from './slices/validator';
import delegatorReducer from './slices/delegator';
import stakingPoolsReducer from './slices/stakingPools';
import searchReducer from './slices/search';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const archPersistConfig = {
  key: 'arch',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['network'],
};

const tokenPersistConfig = {
  key: 'token',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['selectedCurrency'],
};

const rootReducer = combineReducers({
  arch: persistReducer(archPersistConfig, archReducer),
  cluster: clusterReducer,
  token: persistReducer(tokenPersistConfig, tokenReducer),
  validator: validatorReducer,
  delegator: delegatorReducer,
  stakingPools: stakingPoolsReducer,
  search: searchReducer,
});

export { rootPersistConfig, rootReducer };
