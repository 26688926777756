import { createSlice } from '@reduxjs/toolkit';

import { map, mapKeys, chain, reverse } from 'lodash';
import { fShortenNumber } from '../../utils/formatNumber';

import { NANOSECONDS_IN_A_MILLISECOND } from '../../constants/general';
import { ALERTS, BLOCK_HEIGHT_RANGE } from '../../constants/near';

import axios from '../../utils/axios';

import { dispatch } from '../store';
import { addAlert, deleteAlert } from './arch';

const initialState = {
  isLoading: false,
  error: null,

  network: {
    epochId: '',
    epochHeight: 0,
    epochLength: 0,
    blockTime: 0,
    nodesCount: 0,
    updatedNodesCount: 0,
    protocolVersion: '',
    latestVersion: '',
    updateRatio: 0,
    startDate: '',
    startHeight: 0,
    totalSupply: 0,
    apy: 0,
    tvl: 0,
    producedBlocks: 0,
    expectedBlocks: 0,
    blockPerformance: 0,
    producedChunks: 0,
    expectedChunks: 0,
    chunkPerformance: 0,
    nodesMissingBlocksCount: 0,
    nodesMissingBlocksStake: 0,
    isUpdatingEpoch: false,
  },

  blocks: {
    current: {
      author: '-',
      height: 0,
      timestamp: 0,
      hash: '',
      epochId: '',
    },
    previous: {
      author: '-',
      height: 0,
      timestamp: 0,
      hash: '',
      epochId: '',
    },
  },

  nodes: {},

  historical: {},

  historicalYield: [],
};

const slice = createSlice({
  name: 'cluster',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCurrentBlockSuccess(state, action) {
      const block = action.payload;

      state.isLoading = false;
      // state.blocks.previous = state.blocks.current;
      state.blocks.current = block;
    },

    getCurrentBlockRangeSuccess(state, action) {
      const [previous, current] = action.payload;

      state.isLoading = false;
      state.blocks.previous = previous;
      state.blocks.current = current;
    },

    getNodesStatusSuccess(state, action) {
      const nodes = action.payload;

      state.isLoading = false;
      state.nodes = nodes;
    },

    getNetworkStatusSuccess(state, action) {
      const network = action.payload;

      state.isLoading = false;
      state.network = network;
    },

    getNetworkHistoricalYieldSuccess(state, action) {
      const historicalYield = action.payload;

      state.isLoading = false;
      state.historicalYield = historicalYield;
    },

    getNetworkHistoricalSuccess(state, action) {
      const networkHistorical = action.payload;

      state.isLoading = false;
      state.historical = networkHistorical;
    },
  },
});

// Reducer
export default slice.reducer;

// Thunks
function blockAdapter(block) {
  block.timestamp = Math.floor(block.timestamp / NANOSECONDS_IN_A_MILLISECOND);
  block.epochId = block.epoch_id;
  delete block.epoch_id;

  return block;
}

export function getCurrentBlock() {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get('/network/block-head');
      dispatch(slice.actions.getCurrentBlockSuccess(blockAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function blockRangeAdapter(blocks) {
  return blocks.map((block) => blockAdapter(block));
}

export function getCurrentBlockRange(range = BLOCK_HEIGHT_RANGE) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/block-head-range/${range}`);
      dispatch(slice.actions.getCurrentBlockRangeSuccess(blockRangeAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function nodesAdapter(nodes) {
  const nodeKeysMap = {
    epoch_id: 'epochId',
    account_id: 'accountId',
    is_validator: 'isValidator',
    ip_address: 'ipAddress',
    sync_status: 'syncStatus',
    stake_next_epoch: 'stakeNextEpoch',
    last_seen: 'lastSeen',
    last_height: 'lastHeight',
    kickout_reason: 'kickoutReason',
    kickout_value: 'kickoutValue',
    is_slashed: 'isSlashed',
    num_produced_blocks: 'numProducedBlocks',
    num_expected_blocks: 'numExpectedBlocks',
    num_produced_chunks: 'numProducedChunks',
    num_expected_chunks: 'numExpectedChunks',
    country_code: 'countryCode',
  };

  const nodesByKey = {};

  chain(nodes)
    .map((node) => mapKeys(node, (_, key) => nodeKeysMap[key] || key))
    .map((node) => {
      nodesByKey[node.accountId] = node;
      return nodesByKey;
    })
    .value();

  return nodesByKey;
}

export function getNodesStatus() {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get('/db/epoch/nodes');
      dispatch(slice.actions.getNodesStatusSuccess(nodesAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function networkStatusAdapter(status) {
  return {
    epochId: status.epoch_id,
    epochHeight: status.epoch_height,
    epochLength: status.epoch_length,
    blockTime: status.block_time,
    nodesCount: status.nodes_count,
    updatedNodesCount: status.nodes_with_latest_version_count,
    protocolVersion: status.protocol_version,
    latestVersion: status.latest_version,
    updateRatio: status.update_ratio,
    startDate: status.start_date,
    startHeight: status.start_height,
    totalSupply: status.total_supply,
    apy: status.apy,
    tvl: status.tvl,
    producedBlocks: status.num_produced_blocks,
    expectedBlocks: status.num_expected_blocks,
    blockPerformance: status.block_performance,
    producedChunks: status.num_produced_chunks,
    expectedChunks: status.num_expected_chunks,
    chunkPerformance: status.chunk_performance,
    nodesMissingBlocksCount: status.nodes_missing_blocks_count,
    nodesMissingBlocksStake: status.nodes_missing_blocks_stake,
    isUpdatingEpoch: status.is_updating_epoch,
  };
}

export function getNetworkStatus() {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get('/db/epoch/network');

      dispatch(slice.actions.getNetworkStatusSuccess(networkStatusAdapter(response.data)));

      // NOTE mock
      // dispatch(addAlert(ALERTS.infoEpochUpdating));
      // dispatch(addAlert(ALERTS.errorDataFetching));

      if (response?.data?.is_updating_epoch) {
        dispatch(addAlert(ALERTS.infoEpochUpdating));
      } else {
        dispatch(deleteAlert(ALERTS.infoEpochUpdating));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function networkHistoricalYieldAdapter(historicalYield) {
  return map(historicalYield, (epochYield) => ({
    epochHeight: epochYield.epoch_height,
    validatorsCount: Number(epochYield.validators_count),
    averageCommission: Number(fShortenNumber(epochYield.average_commission)),
    networkApy: Number(epochYield.network_apy),
  }));
}

export function getNetworkHistoricalYield(epochsCount = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/network/yield/${epochsCount}`);
      dispatch(slice.actions.getNetworkHistoricalYieldSuccess(networkHistoricalYieldAdapter(response.data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}

function networkHistoricalAdapter(networkHistorical) {
  return map(networkHistorical, (status) => ({
    epochId: status.epoch_id,
    epochHeight: status.epoch_height,
    startDate: status.start_date,
    startHeight: status.start_height,
    blockTime: status.block_time,
    totalSupply: status.total_supply,
    apy: status.apy,
    tvl: status.tvl,
    latestVersion: status.latest_version,
    nodesCount: status.nodes_count,
    nodesWithLatestVersionCount: status.nodes_with_latest_version_count,
    updateRatio: status.update_ratio,
    numProducedBlocks: status.num_produced_blocks,
    numExpectedBlocks: status.num_expected_blocks,
    blockPerformance: status.block_performance,
    numProducedChunks: status.num_produced_chunks,
    numExpectedChunks: status.num_expected_chunks,
    chunkPerformance: status.chunk_performance,
    nodesMissingBlocksCount: status.nodes_missing_blocks_count,
    nodesMissingBlocksStake: status.nodes_missing_blocks_stake,
  }));
}

export function getNetworkHistoricalStatus(epochsCount = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/db/epoch/network/${epochsCount}`);
      dispatch(slice.actions.getNetworkHistoricalSuccess(networkHistoricalAdapter(reverse(response.data))));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(addAlert(ALERTS.errorDataFetching));
    }
  };
}
