export const PROTOCOL_INFLATION = 0.05;
export const PROTOCOL_TREASURY_REWARD = 0.1;
export const VALIDATORS_SLOTS = 100;
export const CHUNK_PRODUCERS_SLOTS = 100;

export const YOCTOS_IN_ONE_NEAR = 10 ** 24;

export const ACCOUNT_RESERVED_YOCTOS_FOR_TX = 0.05 * YOCTOS_IN_ONE_NEAR;

export const BLOCK_HEIGHT_RANGE = 60;

export const POOL_ACCOUNT_ID_DEXAGON = 'dexagon.poolv1.near';

export const PATH_NEAR_EXPLORER = 'https://explorer.near.org/';
export const PATH_BLOCKSCOPE_DELEGATE = `https://wallet.near.org/staking/${POOL_ACCOUNT_ID_DEXAGON}/stake`;
export const PATH_BLOCKSCOPE_WEBSITE = 'https://blockscope.net';

export const NEAR_NETWORK_MAINNET = 'mainnet';
export const NEAR_NETWORK_TESTNET = 'testnet';

export const CENTRALIZATION_SCORE_LOW = 1;
export const CENTRALIZATION_SCORE_MID = 2;
export const CENTRALIZATION_SCORE_HIGH = 3;

export const CENTRALIZATION_THRESHOLD_HIGH = 2.49;
export const CENTRALIZATION_THRESHOLD_MID = 1.5;

export const PERFORMANCE_SCORE_VERY_LOW = 0;
export const PERFORMANCE_SCORE_LOW = 1;
export const PERFORMANCE_SCORE_MID = 2;
export const PERFORMANCE_SCORE_HIGH = 3;

export const PERFORMANCE_THRESHOLD_NOTICE = 95;
export const PERFORMANCE_THRESHOLD_WARNING = 90;
export const PERFORMANCE_THRESHOLD_KICKOUT = 80;

export const COMMISSION_ZERO = 0;
export const COMMISSION_THRESHOLD_WARNING = 10;
export const COMMISSION_THRESHOLD_WATCH = 20;

export const COMMISSION_ZERO_STAKE_MID_THRESHOLD = 500000;
export const COMMISSION_ZERO_STAKE_MAX_THRESHOLD = 2000000;

export const COMMISSION_SCORE_LOW = 0;
export const COMMISSION_SCORE_MID = 1;
export const COMMISSION_SCORE_HIGH = 2;

export const NODE_SYNC_STATUS = {
  synced: 'NoSync',
  syncingHearders: 'HeadersSync',
  syncingBody: 'BodySync',
  syncingState: 'StateSync',
  noPeers: 'AwaitingPeers',
};

export const STAKING_POOLS_IMG_FOLDER = 'staking_pools';

export const SPONSORED_POOL = [
  {
    image: '/logo_blockscope/blockscope_logo_small_squared.png',
    name: 'Blockscope, secure and low commission professional validator',
    description: 'Sponsored',
    poolId: POOL_ACCOUNT_ID_DEXAGON,
    poolName: 'Blockscope',
  },
];

export const ALERTS = {
  infoEpochUpdating: {
    type: 'EPOCH_UPDATING',
    severity: 'info',
    message: 'New epoch data is being calculated, please wait few minutes to get data updated.',
    display: true,
  },

  errorDataFetching: {
    type: 'DATA_FETCHING',
    severity: 'error',
    message: 'There are issues with backend connectivity.',
    display: true,
  },

  warningMetricsUnavailable: {
    type: 'DATA_FETCHING',
    severity: 'warning',
    message: 'Cannot access node metrics service, check that service is enabled and server port open.',
    display: true,
  },
};

export const TOOLTIPS = {
  networkDescription: 'This card displays info about the overall network status',
  networkOverallEpoch: ' elapsed percentage and time left for the current epoch',
  networkOverallUpgradeRatio: ' number and ratio of active validator nodes running the latest protocol version',
  networkOverallPerformance: ' average performance of all active validator nodes (blocks, chunks and total)',
};
