// hooks
import useSettings from '../../../hooks/useSettings';
// components
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';

export default function ThemeModeToggle() {
  const { themeMode, onToggleMode } = useSettings();

  return (
    <>
      <IconButtonAnimate onClick={onToggleMode} sx={{ p: 0 }}>
        <Iconify icon={themeMode === 'light' ? 'eva:moon-fill' : 'eva:sun-fill'} width={24} height={24} />
      </IconButtonAnimate>
    </>
  );
}
