import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Input, Slide, Button, InputAdornment, ClickAwayListener } from '@mui/material';

import { useDispatch, useSelector } from '../../../redux/store';
import { toggleSearchBar } from '../../../redux/slices/search';

import cssStyles from '../../../utils/cssStyles';

import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';

// import { isValidPoolId, isValidAccountId } from '../../../utils/nearscope/general';

import { selectIsOpenSearchBar } from '../../../redux/selectors/search';

import { PATH_DASHBOARD } from '../../../routes/paths';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SEARCH_TERM_MIN_CHARS = 3;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Searchbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [term, setTerm] = useState('');
  const [buttonVariant, setButtonVariant] = useState('disabled');
  const isOpenSearchBar = useSelector(selectIsOpenSearchBar);

  const handleOpen = () => {
    dispatch(toggleSearchBar(true));
  };

  const handleClose = () => {
    dispatch(toggleSearchBar(false));
    setTerm('');
    setButtonVariant('disabled');
  };

  const handleSubmit = () => {
    if (term.length >= SEARCH_TERM_MIN_CHARS) {
      // if (isValidPoolId(term)) {
      //   navigate(PATH_DASHBOARD.validator.view(term), { replace: true });
      // } else if (isValidAccountId(term)) {
      //   navigate(PATH_DASHBOARD.delegator.view(term), { replace: true });
      // } else {
      //   navigate(PATH_DASHBOARD.search.view(term), { replace: true });
      // }

      handleClose();
      navigate(PATH_DASHBOARD.search.view(term), { replace: true });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleTermChange = (event) => {
    setTerm(event.target.value === '' ? '' : event.target.value.trim());

    if (event.target.value.length >= SEARCH_TERM_MIN_CHARS) {
      setButtonVariant('contained');
    } else {
      setButtonVariant('disabled');
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpenSearchBar && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpenSearchBar} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search for validators or accounts…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              onChange={handleTermChange}
              onKeyDown={handleKeyDown}
            />
            <Button variant={buttonVariant} onClick={handleSubmit}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
