import { map, values } from 'lodash';
import { useState, useEffect } from 'react';

import { MenuItem, Stack, Typography } from '@mui/material';

import { useDispatch, useSelector } from '../../../redux/store';

import { setSelectedCurrency } from '../../../redux/slices/token';

import { selectSelectedCurrency } from '../../../redux/selectors/token';

import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import { CURRENCIES } from '../../../constants/general';

export default function CurrencyPopover() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [currency, setCurrency] = useState(CURRENCIES.usd);
  const { value: selected } = useSelector(selectSelectedCurrency);

  useEffect(() => {
    setCurrency(CURRENCIES?.[selected] || CURRENCIES.usd);
  }, [selected]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelect = (currency) => {
    handleClose();
    dispatch(setSelectedCurrency(currency));
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <Image disabledEffect src={currency?.icon} alt={currency?.label} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <Typography variant="caption" align="center" sx={{ py: 1, flexShrink: 0, color: 'text.secondary' }}>
            Select currency...
          </Typography>

          {map(values(CURRENCIES), (option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currency?.value}
              onClick={() => handleSelect(option.value)}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
